import React from 'react';
import { Link } from 'gatsby';
import { isMobile } from 'react-device-detect';
import CBGIsolateImg from '../../assets/images/cbgIsolate.png';
import CBGFlowerImg from '../../assets/images/cbgFlower.png';
import CBGOilWholeImg from '../../assets/images/cbgoildwholsale.png';

const PageList = () => {
  return (
    <section
      class="pageList"
      style={{
        borderBottom: '1px solid #000080',
      }}
    >
      <Link
        class="pageList-item _component-color-red pagelist-first"
        to="/cbg-isolate-wholesale"
        title="Learn about the science behind Civilized Savage"
        style={{
          color: '#0b2436',
          borderRight: isMobile ? 'none' : '1px solid #000080',
          borderTop: '1px solid #000080',
          backgroundColor: '#fff',
        }}
      >
        <div class="pageList-content">
          <header class="pageList-header">
            <h2 class="pageList-headline">
              CBG Isolate
            </h2>
          </header>
          <div class="pageList-asset pageList-asset-first">
            <img src={CBGIsolateImg} className="pageList-image" alt="Local" />
          </div>
        </div>
      </Link>

      <Link
        class="pageList-item _component-color-blue pagelist-second"
        to="/cbg-flower-wholesale"
        title="Learn about Civilized Savage's approach"
        style={{
          color: '#0b2436',
          backgroundColor: '#fff',
          borderTop: '1px solid #000080',
          borderRight: isMobile ? 'none' : '1px solid #000080',
        }}
      >
        <div class="pageList-content">
          <header class="pageList-header">
            <h2 class="pageList-headline">CBG Flower</h2>
          </header>
          <div class="pageList-asset pageList-asset-second">
            <img src={CBGFlowerImg} className="pageList-image" alt="National" />
          </div>
        </div>
      </Link>

      <Link
        class="pageList-item _component-color-green pagelist-third"
        to="/cbg-oil-wholesale"
        title="Learn about practical applications"
        style={{
          color: '#0b2436',
          backgroundColor: '#fff',
          borderTop: '1px solid #000080',
        }}
      >
        <div class="pageList-content">
          <header class="pageList-header">
            <h2 class="pageList-headline">
              CBG Oil Wholesale
            </h2>
          </header>
          <div class="pageList-asset pageList-asset-third">
            <img
              src={CBGOilWholeImg}
              className="pageList-image"
              alt="Headless"
            />
          </div>
        </div>
      </Link>
    </section>
  );
};

export default PageList;
