import React, { Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import Hero from "../components/hero";
import PageList from "../components/pageList";
import JobSection from "../components/jobSection";
import Footer from "../components/footer";

const IndexPage = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Fragment>
      <Helmet>
        <title>Organic CBG Wholesale Florida | CBG Wholesale California</title>
        <meta
          name="description"
          content="
          CBG Wholesalers has organic, high quality CBG products  available for wholesale in California, Texas, and Florida. Our CBG wholesale comes at the best prices, with full consistency at 97.5 purity."
        />
        <link href="https://cbgwholesalers.com/" rel="canonical" />
      </Helmet>
      <main>
        <Header />
        <Hero />
        <PageList />
        <JobSection showModal={() => setShowModal(true)} />
        <Footer />
      </main>
      {showModal && (
        <div className="popup-overlay">
          <div className="content-container">
            <div className="content-wrapper">
              <div className="header">
                <div className="inner">
                  <div>Why SEO Cannabis Agency</div>
                  <div
                    className="close"
                    role="none"
                    onClick={() => setShowModal(false)}
                  ></div>
                </div>
              </div>
              <div className="content">
                <p>
                  CBG Wholesalers is the top online marketplace for wholesale
                  CBG products. When you need to buy any CBG products in bulk,
                  head over to our easy-to-use website. Forget about the days of
                  buying merchandise in bulk from multiple wholesalers. We make
                  it simple to order the best CBG products in bulk from elite
                  brands you can trust, all in one convenient place.
                  <br />
                  <br />
                  What exactly is CBG? CBG, shortened from Cannabigerol, is a
                  hemp-derived cannabinoid that delivers relief to uncomfortable
                  symptoms by bonding with receptors along the nervous system.
                  Featuring powerful therapeutic properties, CBG may help
                  relieve pain, nausea, and inflammation. Additionally,
                  according to some early research, CBG may also be beneficial
                  in inhibiting the growth of cancerous cells. Users love that
                  CBG helps them get potent relief without an uncomfortable high
                  sensation. Since it’s not psychoactive, your customers can
                  enjoy the benefits of a hemp-derived product without getting
                  couch-locked or paranoid.
                  <br />
                  <br />
                  Why add CBG to your product lineup? The answer is simple.
                  People across the country are asking for CBG oil, flower,
                  isolate, and distillate products from their favorite
                  cannabinoid brands. Your customers trust you to provide the
                  highest-quality hemp-derived products, so offer them CBG from
                  a wholesaler dedicated to offering safe and effective
                  products.
                  <br />
                  <br />
                  Why choose CBG Wholesalers? We make the wholesale purchasing
                  process fast and simple. From our hands-on customer service
                  team to our easy-to-use website, working with CBG Wholesalers
                  is a breeze. Our knowledgeable team can answer any questions
                  you may have about the CBG products we offer. We help you
                  fulfill your orders quickly and keep your customers satisfied.
                  With a huge selection of merchandise available to you in just
                  one click, CBG Wholesalers is the place to shop for all of
                  your CBG product needs. Forget about the days where you
                  managed huge bulk orders from multiple wholesalers. With our
                  incredible customer service team, incredible variety, and
                  amazing quality, CBG Wholesalers is the one-stop shop for all
                  of your CBG needs.
                  <br />
                  <br />
                  CBG Wholesalers carries a huge assortment of CBG products from
                  the most reputable brands on the market. Whether you need CBG
                  flower, isolate, oil, distillate, or carts, CBG Wholesalers
                  has it all at the most affordable prices. We personally vet
                  each brand that we onboard for quality, safety, and efficacy
                  of products. We stand by the products and brands we offer
                  because your customers deserve high-quality products they can
                  trust. You can’t get CBG products this nice for such a great
                  price anywhere else.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default IndexPage;
