import React from 'react';
import { Link } from 'gatsby';
import HandImg from '../../assets/images/bottomBanner.png';

const JobSection = ({ showModal }) => {
  return (
    <section
      className="jobsCta publicfigure"
      style={{ backgroundColor: '#fff', backgroundImage: `url(${HandImg})` }}
      role="none"
    >
      <div className="jobsCta-container">
        <div className="jobsCta-stack">
          <h2
            className="jobsCta-headline"
            style={{ color: '#0b2436', borderBottom: '1px solid #0b2436' }}
          >
            Why choose CBG wholesalers?
            <br />
          </h2>

          <p
            className="jobsCta-subheading mobile"
            style={{ color: '#0b2436', fontSize: 13, lineHeight: '17px' }}
          >
            CBG Wholesalers is the top online marketplace for wholesale CBG
            products. When you need to buy any CBG products in bulk, head over
            to our easy-to-use website. Forget about the days of buying
            merchandise in bulk from multiple wholesalers. We make it simple to
            order the best CBG products in bulk from elite brands you can trust,
            all in one convenient place....
            <button className="read-more" onClick={showModal}>
              Read More
            </button>
          </p>
          <p
            className="jobsCta-subheading desktop"
            style={{ color: '#0b2436', fontSize: 13, lineHeight: '17px' }}
          >
            CBG Wholesalers is the top online marketplace for wholesale CBG
            products. When you need to buy any CBG products in bulk, head over
            to our easy-to-use website. Forget about the days of buying
            merchandise in bulk from multiple wholesalers. We make it simple to
            order the best CBG products in bulk from elite brands you can trust,
            all in one convenient place.
            <br />
            <br />
            What exactly is CBG? CBG, shortened from Cannabigerol, is a
            hemp-derived cannabinoid that delivers relief to uncomfortable
            symptoms by bonding with receptors along the nervous system.
            Featuring powerful therapeutic properties, CBG may help relieve
            pain, nausea, and inflammation. Additionally, according to some
            early research, CBG may also be beneficial in inhibiting the growth
            of cancerous cells. Users love that CBG helps them get potent relief
            without an uncomfortable high sensation. Since it’s not
            psychoactive, your customers can enjoy the benefits of a
            hemp-derived product without getting couch-locked or paranoid.
            <br />
            <br />
            Why add CBG to your product lineup? The answer is simple. People
            across the country are asking for CBG oil, flower, isolate, and
            distillate products from their favorite cannabinoid brands. Your
            customers trust you to provide the highest-quality hemp-derived
            products, so offer them CBG from a wholesaler dedicated to offering
            safe and effective products.
            <br />
            <br />
            Why choose CBG Wholesalers? We make the wholesale purchasing process
            fast and simple. From our hands-on customer service team to our
            easy-to-use website, working with CBG Wholesalers is a breeze. Our
            knowledgeable team can answer any questions you may have about the
            CBG products we offer. We help you fulfill your orders quickly and
            keep your customers satisfied. With a huge selection of merchandise
            available to you in just one click, CBG Wholesalers is the place to
            shop for all of your CBG product needs. Forget about the days where
            you managed huge bulk orders from multiple wholesalers. With our
            incredible customer service team, incredible variety, and amazing
            quality, CBG Wholesalers is the one-stop shop for all of your CBG
            needs.
            <br />
            <br />
            CBG Wholesalers carries a huge assortment of CBG products from the
            most reputable brands on the market. Whether you need CBG flower,
            isolate, oil, distillate, or carts, CBG Wholesalers has it all at
            the most affordable prices. We personally vet each brand that we
            onboard for quality, safety, and efficacy of products. We stand by
            the products and brands we offer because your customers deserve
            high-quality products they can trust. You can’t get CBG products
            this nice for such a great price anywhere else.
          </p>
          <p
            className="jobsCta-cta"
            style={{ color: '#0b2436', marginBottom: 10 }}
          >
            <Link
              className="button -cta no-margin"
              to="/contact"
              title="Reach Out"
              style={{ color: '#0b2436' }}
            >
              Reach Out
              <span className="arrowPacman">
                <span className="arrowPacman-clip">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.10081 0L5.88245 1.23617L10.7016 6.12576H0V7.87423H10.7016L5.88245 12.7638L7.10081 14L14 7L7.10081 0Z"
                      fill="#0b2436"
                    ></path>
                  </svg>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.10081 0L5.88245 1.23617L10.7016 6.12576H0V7.87423H10.7016L5.88245 12.7638L7.10081 14L14 7L7.10081 0Z"
                      fill="#0b2436"
                    ></path>
                  </svg>
                </span>
              </span>
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default JobSection;
