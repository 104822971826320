import React from 'react';
import { Link } from 'gatsby';
import HomeImg from '../../assets/images/homeBanner.png';

const Hero = () => {
  return (
    <section
      class="hero -home"
      style={{ backgroundImage: `url(${HomeImg})` }}
      role="none"
    >
      <div class="hero-stack">
        <h1 class="hero-headline">
          CBG Wholesalers
          <br />
          <span>The best CBG wholesale deals in the US.</span>
        </h1>
        <p class="hero-cta -home">
          <Link
            class="button -cta"
            to="/cbg-isolate-wholesale"
            title="See job listings for Civilized Savage"
          >
            CBG ISOLATE
            <span class="arrowPacman">
              <span class="arrowPacman-clip">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.10081 0L5.88245 1.23617L10.7016 6.12576H0V7.87423H10.7016L5.88245 12.7638L7.10081 14L14 7L7.10081 0Z"
                    fill="#0b2436"
                  ></path>
                </svg>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.10081 0L5.88245 1.23617L10.7016 6.12576H0V7.87423H10.7016L5.88245 12.7638L7.10081 14L14 7L7.10081 0Z"
                    fill="#0b2436"
                  ></path>
                </svg>
              </span>
            </span>
          </Link>
          <Link class="button -cta" to="/cbg-flower-wholesale">
            CBG FLOWER
            <span class="arrowPacman">
              <span class="arrowPacman-clip">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.10081 0L5.88245 1.23617L10.7016 6.12576H0V7.87423H10.7016L5.88245 12.7638L7.10081 14L14 7L7.10081 0Z"
                    fill="white"
                  ></path>
                </svg>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.10081 0L5.88245 1.23617L10.7016 6.12576H0V7.87423H10.7016L5.88245 12.7638L7.10081 14L14 7L7.10081 0Z"
                    fill="white"
                  ></path>
                </svg>
              </span>
            </span>
          </Link>
        </p>
      </div>
    </section>
  );
};

export default Hero;
